import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import {PhotoshopPicker} from "react-color";
import {Modal} from "reactstrap";
import RichTextMarkdown from "../utils/RichTextMarkdown";
import {isEmptyHtmlEntity} from "../utils/HelpfulFunction";

class SetUpTicketEmail extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            emailVariables: '',
            loading:true,
            showTabOneemailComponents: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidMount() {
        this.emailVariablesRef = base.listenTo(`emailVariables`, {
            context: this,
            state: 'emailVariables',
            then(data){
                let advanced = false;
                if(data && data.sender){
                    advanced = true;
                }
                this.setState({
                    loading: false,
                    advanced: advanced,
                    emailVariables: data
                })
            }
        });
    }

    componentWillUnmount() {
        base.removeBinding(this.emailVariablesRef);
    }

    handleChange(event){
        let target = event.target;
        let value = target.value;
        if(target.type === 'checkbox'){
            value = target.checked;
            this.setState({[target.name]: value})
        } else {
            let emailVariablesCopy = this.state.emailVariables;
            emailVariablesCopy[event.target.name] = value
            this.setState({ emailVariables:  emailVariablesCopy});
        }
    }

    handleImageChange(event){
      const name_of_file = event.target.name;
      const target = this[name_of_file];
      const file_to_update =target.files[0];
      this.setState({loading:true})
      const vm = this;
      this.UploadImage.upload_file(file_to_update).then(res => {
          vm.setState({loading:false})
          if(res.error){
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          } else if(res.imageUrl) {
              let emailVariablesCopy = vm.state.emailVariables;
              emailVariablesCopy[name_of_file] = res.imageUrl
              vm.setState({
                  emailVariables: emailVariablesCopy,
              }, () => {
                  vm[name_of_file].value = ""
              });
          } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: "Something went wrong, please re-upload your image and try again!",
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          }
      })
    }

    openColorPicker(colorToChange){
        this.setState({
            colorPickerShow: true,
            colorToShow: this.state[colorToChange],
            colorToUpdate: colorToChange
        })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        const emailVariablesCopy = this.state.emailVariables;
        emailVariablesCopy[this.state.colorToUpdate] = this.state.colorToShow;
        this.setState({
            emailVariables: emailVariablesCopy,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        this.setState({
            colorPickerShow: false,
        })
    }

    onChangeHelpText = (helpText) => {
        let emailVariablesCopy = this.state.emailVariables;
        emailVariablesCopy["helpText"] = helpText;
        this.setState({ emailVariables:  emailVariablesCopy});
    }

    handleSubmit(event) {
      event.preventDefault();
        const emailVariables = this.state.emailVariables;
        let sender = emailVariables.sender || "";
        let subjectLine = emailVariables.subjectLine || "";
        let helpText = emailVariables.helpText || "";
        let replyTo = emailVariables.replyTo || "";
        let backgroundImage = emailVariables.emailBackgroundImage || "";
        if(isEmptyHtmlEntity(helpText)){
            helpText = "";
        }
        this.setState({loading:true})
        const updateRulesObject = {}
        updateRulesObject['helpText'] = helpText;
        updateRulesObject['subjectLine'] = subjectLine;
        updateRulesObject['replyTo'] = replyTo;
        updateRulesObject['emailBackgroundImage'] = backgroundImage;
        updateRulesObject['sender'] = sender;
      const vm = this;
      base.post("emailVariables/", {
        data: updateRulesObject,
        then(err){
          vm.setState({loading:false})
          if(!err){
            swal({
                title: 'Variables Updated!',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
              })
          } else {
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
              })
            // console.log(err)
          }
        }
      })
    }

    render() {
        let sender = this.state.emailVariables.sender || "";
        let replyTo = this.state.emailVariables.replyTo || "";
        let subjectLine = this.state.emailVariables.subjectLine || "";
        let preview = this.state.emailVariables.preview || "";
        let helpText = this.state.emailVariables.helpText || "";
        let emailBackgroundImage = this.state.emailVariables.emailBackgroundImage || "";

        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <p className="admin-header-text" style={{marginBottom:0}}>{isMlbApp ? "Message Branding" : "Email Branding"}</p>
              <p className="admin-subheader-text">This is where you can edit the {isMlbApp ? "message" : "email"} sent to fans</p>
              <div className="container-out">
                <div className="admin-form-box">
                  <form onSubmit={this.handleSubmit} id="create-email-form">
                    <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:20}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                      <div className="row col-md-12">
                          <div className="form-group">
                              <label htmlFor="subjectLine">{isMlbApp ? "Message": "Email"} Subject Line</label>
                              <div className="form-text">This is the subject line that your fans will see when they receive their winning {isMlbApp ? "messages": "emails"}</div>
                              <input id="subjectLine" name="subjectLine" className="form-control" value={subjectLine} onChange={this.handleChange} />
                          </div>
                      </div>
                      {!isMlbApp &&
                          <>
                            <div className="row" style={{marginTop: 10}}>
                              <div className="form-group">
                                  <label htmlFor="replyTo" className="form-label">Reply To Address (if different than send address)</label>
                                  <div className="form-text">This is the reply address your fans will see when they reply to the prize email. We recommend a support email or something where you can help your fans with the prize they win</div>
                                  <input id="replyTo" name="replyTo" type="email" className="form-control" value={replyTo} onChange={this.handleChange} />
                              </div>
                            </div>
                            <div className="row" style={{marginTop: 10}}>
                              <div className="form-group">
                                  <label htmlFor="preview" className="form-label">Email Preview Text</label>
                                  <div className="form-text">This is the preview text for the email, if left blank it will be replaced by the subject line. For mobile optimization the length should be between 35-50 characters but for desktop the preview can be up to 140 characters and beyond</div>
                                  <input id="preview" name="preview" className="form-control" value={preview} onChange={this.handleChange}/>
                              </div>
                            </div>
                              <div className="row">
                                  <div className="form-group col-md-3">
                                      <RichTextMarkdown
                                          field={{
                                              id: "helpText",
                                              name: "helpText",
                                              value: helpText
                                          }}
                                          form={{
                                              setFieldValue: (field, value) => this.onChangeHelpText(value)
                                          }}
                                          label="Help Text"
                                          sublabel="This is where you can tell fans where to contact you with issues."
                                      />
                                  </div>
                                  <div className="form-group col-md-3" align="center">
                                      <img src={emailBackgroundImage} width="auto" height="100px" alt=""/>
                                      <br/>
                                      <label htmlFor="backgroundImage">Email Header Image<br/>(700px X 400px)</label>
                                      <div className="form-group">
                                          <input style={{display:'none'}}  id="emailBackgroundImage" name="emailBackgroundImage" type="file" ref={input => {this.emailBackgroundImage = input; }} onChange={this.handleImageChange}/>
                                          <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('emailBackgroundImage').click()} />
                                      </div>
                                  </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="showAdvancedSettings">Advanced</label>
                                <br/>
                                <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                              </div>
                              {this.state.advanced &&
                                  <div className="form-group">
                                  <label htmlFor="sender">Email Sender</label>
                                  <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the sender your fans will see when they receive their winning emails. BE AWARE: changing the sender could adversely impact delivery rates</p>
                                  <input id="sender" name="sender" type="email" className="form-control" value={sender} onChange={this.handleChange} />
                                  </div>
                              }
                          </>
                      }
                  </form>
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
              <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
            </Modal>
         </div>
        );
    }
}

export default SetUpTicketEmail
