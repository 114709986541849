import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { base, appDatabasePrimaryFunctions } from '../../base';
import swal from 'sweetalert2';
import '../../styles/css/AdminMain.css';
import Dropzone from 'react-dropzone';
import {PhotoshopPicker} from "react-color";
import DatePicker from '../utils/DatePicker';
import RichTextMarkdown from "../utils/RichTextMarkdown";

class SetUpTickets extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            ticketList: [],
            editingTicket: false,
            rewardToEdit: null,
            imageUrl: null,
            modal: false,
            emailImage: null,
            rewardName: "",
            rewardsList: [],
            rewardLink: "",
            rewardLinkButtonText: "",
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            colorsTab: true,
            rewardDescription: "",
            editTicketId: "",
            editTicketEmailImage: '',
            editTicketPictureUnderScratcher: '',
            revealImagePreview: {},
            emailImagePreview: {},
            loading:true,
            files: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      this.rewardsListRef = base.syncState(`rewardsList`, {
        context: this,
        state: 'rewardsList',
        asArray: true
      });
      this.setState({loading:false})
    }

    onDrop(files, rejected, myArgument) {
      if(rejected.length > 0){
        swal({
            title: 'Image cannot be uploaded',
            text: 'Make sure the image is less than 2mbs and it is an accepted file type',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      this.setState({loading:true})

      this.UploadImage.upload_file(files[0]).then(res => {
        this.setState({loading:false})
          if(res.error){
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          } else if(res.imageUrl) {
              const nameToUpdate = myArgument + "Preview"
              const fileToUpdate = files[0]
              this.setState({
                  emailImage: res.imageUrl,
                  [nameToUpdate]: ({
                      fileToUpdate,
                      preview: URL.createObjectURL(fileToUpdate)
                  })
              });
          } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: "Something went wrong, please re-upload your image and try again!",
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          }
      })
    }

    onChangeDescription = (rewardDescription) => {
        this.setState({
            rewardDescription,
        })
    }

    openColorPicker(colorToChange){
        this.setState({
            colorPickerShow: true,
            colorToShow: this.state[colorToChange],
            colorToUpdate: colorToChange
        })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        const colorToChange = this.state.colorToUpdate;
        this.setState({
            [colorToChange]: this.state.colorToShow,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        this.setState({
            colorPickerShow: false,
        })
    }

    componentWillUnmount() {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(this.state.revealImagePreview.preview);
      URL.revokeObjectURL(this.state.emailImagePreview.preview);
      base.removeBinding(this.rewardsListRef);
    }

    handleChange(evt){
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.state.loading){
            return
        }
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        const rewardName = this.state.rewardName || "";
        const rewardDisplayName = this.state.rewardDisplayName || "";
        const rewardLink = this.state.rewardLink || "";
        const rewardLinkButtonText = this.state.rewardLinkButtonText || "";
        const rewardLinkButtonTextColor = this.state.rewardLinkButtonTextColor || "";
        const rewardLinkButtonColor = this.state.rewardLinkButtonColor || "";
        const prizeSpecificWinningMessage = this.state.prizeSpecificWinningMessage || "";
        const prizeSpecificWinningHeader = this.state.prizeSpecificWinningHeader || "";
        let vendorName = this.state.vendorName || "";
        let templateText = this.state.templateText || "";
        let expiresAtHours = this.state.expiresAtHours || "";
        let expiresAtDateTime = this.state.expiresAtDateTime || "";
        let rewardDescription = this.state.rewardDescription.toString('html');
        if(rewardDescription === "<p><br></p>"){
            rewardDescription = "";
        }
        const emailImage = this.state.emailImage || this.state.editTicketEmailImage || "";
        const showButton = this.state.showButton || false;
        const vm = this;
        if(!rewardName){
            swal({
                title: 'Not all fields filled out!',
                text: 'Please enter them and try again',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        if(vendorName && vendorName.length > 50){
            swal({
                title: 'Oh Uh!',
                text: 'The vendor name is too long, make sure it is 50 characters or less',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }

        if(templateText && templateText.length > 300){
            swal({
                title: 'Oh Uh!',
                text: 'The template text is too long, make sure it is 300 characters or less',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        if(isMlbApp && rewardLinkButtonText && rewardLinkButtonText.length > 15){
            swal({
                title: 'Oh Uh!',
                text: 'The button text is too long, make sure it is 15 characters or less',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        } else if(isMlbApp && !emailImage){
            swal({
                title: 'Oh Uh!',
                text: 'A coupon image is required!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        const rewardToSave = {}
        rewardToSave['rewardName'] = rewardName;
        rewardToSave['rewardDisplayName'] = rewardDisplayName;
        rewardToSave['emailImage'] = emailImage;
        rewardToSave['rewardLink'] = rewardLink;
        rewardToSave['rewardLinkButtonText'] = rewardLinkButtonText;
        rewardToSave['rewardLinkButtonTextColor'] = rewardLinkButtonTextColor;
        rewardToSave['rewardLinkButtonColor'] = rewardLinkButtonColor;
        rewardToSave['showButton'] = showButton;
        rewardToSave['vendorName'] = vendorName;
        rewardToSave['expiresAtDateTime'] = expiresAtDateTime;
        rewardToSave['templateText'] = templateText;
        rewardToSave['prizeSpecificWinningHeader'] = prizeSpecificWinningHeader;
        rewardToSave['prizeSpecificWinningMessage'] = prizeSpecificWinningMessage;
        rewardToSave['expiresAtHours'] = expiresAtHours;
        rewardToSave['description'] = rewardDescription;
        this.setState({loading:true})
        let rewardId = (vm.state.rewardToEdit && vm.state.rewardToEdit.key) || appDatabasePrimaryFunctions.ref().push().key;
        rewardToSave['id'] = rewardId;
        base.post('rewardsList/'+rewardId, {
            data: rewardToSave,
            then(err) {
                if (!err) {
                    swal({
                        title: vm.state.rewardToEdit ? 'Edited Successfully!' : 'Created Successfully!',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    })
                    vm.setState({
                        rewardDescription: "",
                        fileEmailImage: null,
                        emailImage: null,
                        rewardToEdit: null,
                        rewardName: "",
                        rewardDisplayName: "",
                        revealImagePreview: {},
                        emailImagePreview: {},
                        rewardLink: "",
                        rewardLinkButtonText: "",
                        ewardLinkButtonTextColor: "",
                        rewardLinkButtonColor: "",
                        expiresAtHours: "",
                        expiresAtDateTime: "",
                        prizeSpecificWinningHeader: "",
                        prizeSpecificWinningMessage: "",
                        templateText: "",
                        vendorName: "",
                        showButton: false,
                        loading:false,
                        modal: false
                    })
                } else {
                    vm.setState({loading: false})
                    swal({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    })
                    // console.log(err)
                }
            }
        })
    }

    editTicket(event){
      event.preventDefault();
      const array = this.state.rewardsList;
      const reward = array[event.target.value];
      let advanced = false;
      if((reward.expiresAtHours && parseInt(reward.expiresAtHours)) || reward.expiresAtDateTime || reward.prizeSpecificWinningHeader || reward.prizeSpecificWinningMessage) {
          advanced = true;
      }
      this.setState({
          modal: true,
          rewardName: reward.rewardName,
          rewardDisplayName: reward.rewardDisplayName,
          rewardLink: reward.rewardLink,
          rewardDescription: reward.description,
          rewardLinkButtonText: reward.rewardLinkButtonText,
          rewardLinkButtonColor: reward.rewardLinkButtonColor,
          rewardLinkButtonTextColor: reward.rewardLinkButtonTextColor,
          rewardToEdit: reward,
          expiresAtHours: reward.expiresAtHours,
          expiresAtDateTime: reward.expiresAtDateTime,
          prizeSpecificWinningHeader: reward.prizeSpecificWinningHeader,
          prizeSpecificWinningMessage: reward.prizeSpecificWinningMessage,
          editTicketEmailImage:reward.emailImage,
          vendorName: reward.vendorName,
          templateText: reward.templateText,
          editingTicket: true,
          showButton: reward.showButton,
          advanced: advanced
      });
    }

    deleteTicket(e){
      e.preventDefault();
      var array = this.state.rewardsList;
      var index = array[e.target.value];
      swal({
            title: 'Delete Prize?',
            text: 'Are you sure you want to do this?  You will no longer be able to use this prize in any new games',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result)=>{
          if (result.value) {
            base.remove('rewardsList/' + index.key, function(err) {
              if(err){
                swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            })
          }
        })
    }

    toggle() {
      this.setState({
          modal: !this.state.modal,
          rewardName: "",
          rewardDisplayName: "",
          rewardLink: "",
          rewardDescription: "",
          rewardLinkButtonText: "",
          rewardLinkButtonColor: "",
          rewardLinkButtonTextColor: "",
          editTicketEmailImage:null,
          expiresAtHours: "",
          expiresAtDateTime: "",
          prizeSpecificWinningHeader: "",
          prizeSpecificWinningMessage: "",
          templateText: "",
          vendorName: "",
          rewardToEdit: null,
          showButton: false,
          editingTicket: false
      });
    }

    render() {
        const winningTicketList = this.state.rewardsList;
        const emailImagePreview = this.state.emailImagePreview;
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                    <p className="admin-header-text" style={{marginBottom:0}}>Prizes</p>
                    <p className="admin-subheader-text">These are prizes fans will receive when they win</p>
                    <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add Prize</button>
                    <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                      {
                        winningTicketList.map(function(item,i){
                          return <div key={i} className="card">
                              <div className="card-body" align="center">
                                <p style={{marginTop:5}}>{item.rewardName}</p>
                                <p>
                                  <img width="80%" height="auto" src={item.emailImage} alt=""/>
                                </p>
                                <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editTicket.bind(this)} value={i}><span className="fa fa-ellipsis-v"/> Edit</button>
                                <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteTicket.bind(this)} value={i}><span className="fa fa-trash-o"/> Delete</button>
                              </div>
                            </div>
                        }, this)
                      }
                    </div>
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
              <ModalHeader toggle={this.toggle}>Add Prize</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                        <div className="form-group">
                          <label htmlFor="rewardName">Prize Name (Only On The Admin)</label>
                          <input id="rewardName" name="rewardName" type="text" className="form-control" value={this.state.rewardName} onChange={this.handleChange} placeholder="My Sweet Prize"/>
                        </div>
                          {!isMlbApp &&
                              <div className="form-group">
                                  <label htmlFor="rewardDisplayName">Prize Display Name (Optional)</label>
                                  <input id="rewardDisplayName" name="rewardDisplayName" type="text" className="form-control" value={this.state.rewardDisplayName} onChange={this.handleChange} placeholder="My Sweet Prize"/>
                              </div>
                          }
                          {!isMlbApp &&
                              <div className="form-group">
                                  <label htmlFor="rewardLink">Prize Link (Optional)</label>
                                  <input id="rewardLink" name="rewardLink" type="url" className="form-control" value={this.state.rewardLink} onChange={this.handleChange} placeholder="http://my_sweet_prize_link.com"/>
                              </div>
                          }
                          {this.state.rewardLink && !isMlbApp &&
                              <div className="form-group">
                                  <label htmlFor="showButton">Show Button</label>
                                  <br/>
                                  <input type="checkbox" checked={this.state.showButton} id="showButton" name="showButton" onChange={this.handleChange}/>
                              </div>
                          }
                          {((this.state.rewardLink && this.state.showButton) || isMlbApp) &&
                              <>
                                  <div className="form-group">
                                      <label htmlFor="rewardLinkButtonText">Prize Link Button Text (Optional)</label>
                                      <input id="rewardLinkButtonText" name="rewardLinkButtonText" type="text" className="form-control" value={this.state.rewardLinkButtonText} onChange={this.handleChange} placeholder="Reward Link"/>
                                  </div>
                                  {!isMlbApp &&
                                      <div className="row">
                                          <div className="col-md-6">
                                              <div className="form-inline">
                                                  <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("rewardLinkButtonTextColor")}/>
                                                  <div style={{backgroundColor: this.state.rewardLinkButtonTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                                  <label htmlFor="rewardLinkButtonTextColor" style={{marginRight: 10}}>Button Text Color</label>
                                                  <input id="rewardLinkButtonTextColor" name="rewardLinkButtonTextColor" type="text" className="form-control" value={this.state.rewardLinkButtonTextColor} onChange={this.handleChange} placeholder="#000"/>
                                              </div>
                                          </div>
                                          <div className="col-md-6">
                                              <div className="form-inline">
                                                  <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("rewardLinkButtonColor")}/>
                                                  <div style={{backgroundColor: this.state.rewardLinkButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                                  <label htmlFor="rewardLinkButtonColor" style={{marginRight: 10}}>Button Color</label>
                                                  <input id="rewardLinkButtonColor" name="rewardLinkButtonColor" type="text" className="form-control" value={this.state.rewardLinkButtonColor} onChange={this.handleChange} placeholder="#000"/>
                                              </div>
                                          </div>
                                      </div>
                                  }
                              </>
                          }
                          { !isMlbApp &&
                              <div className="form-group">
                                  <RichTextMarkdown
                                      field={{
                                          id: "rewardDescription",
                                          name: "rewardDescription",
                                          value: this.state.rewardDescription
                                      }}
                                      form={{
                                          setFieldValue: (field, value) => this.onChangeDescription(value)
                                      }}
                                      label="Prize Description"
                                      sublabel="Describe the prize that the user will receive."
                                      placeholder="My Sweet Prize Description"
                                  />
                              </div>
                          }
                          {isMlbApp &&
                              <>
                                  <div className="form-group">
                                      <label htmlFor="vendorName">Vendor Name</label>
                                      <input id="vendorName" name="vendorName" type="text" className="form-control" value={this.state.vendorName} onChange={this.handleChange} placeholder="Your sponsors name"/>
                                  </div>
                                  <div className="form-group">
                                      <label htmlFor="templateText">Template Text</label>
                                      <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>This text should finish the phrase: You have an update from </p>
                                      <input id="templateText" name="templateText" className="form-control" value={this.state.templateText} onChange={this.handleChange}/>
                                  </div>
                              </>
                          }
                          <div className="form-group">
                              <label htmlFor="advanced">Advanced</label>
                              <br/>
                              <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                          </div>
                          {this.state.advanced && isMlbApp &&
                              <>
                                  <div className="form-group">
                                      <label htmlFor="expiresAtHours">Hours Until Expire</label>
                                      <input id="expiresAtHours" name="expiresAtHours" step="1" type="number" className="form-control" value={this.state.expiresAtHours} onChange={this.handleChange} placeholder="720"/>
                                  </div>
                                  OR/AND
                                  <div className="form-group row mb-3 form_times">
                                      <label className="col-md-3 col-form-label" htmlFor="expiresAtDateTime"> Date Until Expire</label>
                                      <div className="col-md-9">
                                          <DatePicker showTimeInput dateFormat="Pp" selected={this.state.expiresAtDateTime} onChange={date => this.setState({expiresAtDateTime: date})}/>
                                      </div>
                                  </div>
                              </>
                          }
                          {this.state.advanced &&
                              <>
                                  <div className="form-group">
                                      <label htmlFor="prizeSpecificWinningHeader">Prize Specific Winning Header</label>
                                      <input id="prizeSpecificWinningHeader" name="prizeSpecificWinningHeader" type="text" className="form-control" value={this.state.prizeSpecificWinningHeader} onChange={this.handleChange} placeholder="You Win!"/>
                                  </div>

                                  <div className="form-group">
                                      <label htmlFor="prizeSpecificWinningMessage">Prize Specific Winning Message</label>
                                      <input id="prizeSpecificWinningMessage" name="prizeSpecificWinningMessage" type="text" className="form-control" value={this.state.prizeSpecificWinningMessage} onChange={this.handleChange} placeholder="Congratulations"/>
                                  </div>
                              </>
                          }
                        <div className="form-group" align="center">
                            <label htmlFor="rewardEmailImage" style={{width:'100%'}}>
                                Coupon Image
                                <span style={{cursor:"pointer", display: emailImagePreview.preview || this.state.editTicketEmailImage?"":"none"}} onClick={()=>this.setState({editTicketEmailImage: null, fileUploaded:false, emailImagePreview: {}, emailImage:null})}>
                                  ❌
                              </span>
                            </label>
                          <img src={this.state.editTicketEmailImage} width="auto" height="100px" style={{display: this.state.editingTicket ? '' : 'none' }} alt=""/>
                          <Dropzone
                            className="dropzone dz-clickable"
                            accept="image/*"
                            onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'emailImage')}}
                            multiple={false}
                            maxSize={2200000}>
                            <div className="dz-message needsclick">
                              <span className="fa fa-cloud-upload text-muted" style={{display: emailImagePreview.preview ? 'none' : ''}}/>
                              <h3 style={{display: emailImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                              <img
                                style={{display: emailImagePreview.preview ? '' : 'none'}}
                                src={emailImagePreview.preview}
                                width="100px"
                                height="auto"
                                alt="Drop your image here"
                              />
                            </div>
                          </Dropzone>
                        </div>
                        <div className="form-group text-center">
                          <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
              <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
            </Modal>
         </div>
        );
    }
}

export default SetUpTickets
